<template>
  <v-container fluid class="grey lighten-3">

    <v-card>
      <v-card-title>
        {{ pageTitle }}
      </v-card-title>

      <v-card-text>
        <v-container fluid>

          <v-row>
            <v-col md="6">
              <v-form ref="form">
                <v-text-field v-model="item.name" label="name" required :rules="[v => !!v || 'Name is required']"></v-text-field>
              </v-form>

              <v-card-actions class="pb-5">
                <v-btn class="mr-2" :to="{ name: 'Geos' }">Back</v-btn>
                <v-btn class="mr-4" color="primary" :loading="saving" @click="save()">Save</v-btn>
                <v-btn color="error" :loading="deleting" @click="deleteItem()">Delete</v-btn>

                <v-fade-transition mode="out-in">
                  <v-btn color="green darken-1" text v-show="showSaved">Saved</v-btn>
                </v-fade-transition>

              </v-card-actions>
            </v-col>

            <v-col md="6">
            </v-col>

          </v-row>

          <v-row>
            <v-col>
              <RegionsCountriesTable :regionID="id"></RegionsCountriesTable>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

    </v-card>

  </v-container>
</template>

<script>
import auth from '@/auth'
import formsMixin from '@/mixins/forms'
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'RegionDetail',

  props: {
    id: { type: Number, required: true },
  },

  data () {
    return {
      item: {
        name: null,
      },
      valid: false,
      loading: false,
      saving: false,
      deleting: false,
      showSaved: false,
    }
  },

  mixins: [dataTablesMixin, formsMixin],

  computed: {
    hasSEAPermissions () {
      if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
        return true
      }
      return false
    },
    itemEndpoint () {
      return '/a/common/regions/' + this.id
    },
    pageTitle () {
      return this.item.name ? this.item.name : 'Region'
    }
  },

  components: {
    RegionsCountriesTable: () => import('@/components/tables/RegionsCountriesTable'),
  },

  methods: {
    deleteItem: function () {
      if (!confirm('Are you sure?')) {
        return
      }
      
      this.deleting = true
      var myURL = this.itemEndpoint

      this.$http.delete(myURL).then(resp => {
        this.$store.dispatch('common/loadRegions')
        .catch(errResp => {
          this.$store.commit('system/setAPIError', { method: 'GET', url: 'common/loadRegions', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
        })
        this.$router.push('/geos')
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'DELETE', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.deleting = false)
    },

    fetchItem: function () {
      this.loading = true

      var myURL = this.itemEndpoint + '?xfields='
      // only get the fields needed to fill the item object
      myURL = this.forms_addItemFieldsToURI(myURL, this.item, [])

      this.$http.get(myURL).then(resp => {
        this.item = resp.data.data
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    save: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.saving = true

      // only send the required item fields to API
      delete this.item['id']
      delete this.item['entry_at']
      delete this.item['last_modified_at']

      var body = JSON.stringify(this.item)
      this.$http.put(this.itemEndpoint, body).then(resp => {
        this.showSaved = true
        this.timer = setTimeout(() => { this.showSaved = false }, 3000)
        this.fetchItem()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.itemEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.saving = false)
    },
  },

  created: function () {
    this.fetchItem()
  },
}
</script>

<style scoped>

.settings-label {
  color: rgba(0, 0, 0, 0.6);
}

.shrink >>> label {
  font-size: 14px;
  width: 50px;
}
</style>